
import { Component, Vue, Watch } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Getter, Mutation } from "vuex-class";
import { ISupplierListRequest, ISupplier } from "@/types/supplier";
import { IPagination } from "@/types";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // supplier
  @Action("supplier/adminGetList")
  public getList!: (request: ISupplierListRequest) => boolean;

  @Getter("supplier/list")
  public supplierList!: ISupplier[];

  @Getter("supplier/pagination")
  public pagination!: IPagination<ISupplier[]>;

  @Mutation("supplier/clear")
  public clear!: () => void;

  // watch
  @Watch("$route.query")
  public async changeQuery() {
    this.queryToParams();
    await this.getList(this.params);
  }

  // パラメータ定義
  public params: ISupplierListRequest = {
    search: "",
    page: 1,
    registration_status: 0
  };

  //
  // variables
  //
  public headers = [
    { text: "サプライヤ名", value: "name", sortable: false },
    { text: "読み仮名", value: "kana", sortable: false },
    { text: "短縮表示名", value: "short_name", sortable: false },
    { text: "取扱メーカ", value: "makers_name", sortable: false },
    { text: "サプライヤメモ", value: "memo", sortable: false }
  ];

  public status_items = [
    { value: 0, text: "全て表示" },
    { value: Vue.prototype.$registrationStatus.registered, text: "登録済み" },
    {
      value: Vue.prototype.$registrationStatus.registration_scheduled,
      text: "登録予定"
    },
    {
      value: Vue.prototype.$registrationStatus.no_scheduled,
      text: "登録予定なし"
    }
  ];

  //
  // methods
  //
  private async created() {
    this.clear();
    this.queryToParams();
    await this.getList(this.params);
  }

  // urlからパラメータをセットする
  private queryToParams() {
    const query = this.$route.query;
    this.params = {
      search: query.search ? decodeURI(query.search as string) : "",
      page: query.page ? Number(query.page) : 1,
      registration_status: query.registration_status
        ? Number(query.registration_status)
        : 0
    };
  }

  // 1ページ目から検索
  // 主に検索条件が変更されたときに利用
  public async searchFirst() {
    this.params.page = 1;
    await this.paramsToQuery();
  }

  // URLのパラメータをセットする
  private paramsToQuery() {
    this.$router
      .push({
        path: this.$router.currentRoute.path,
        query: {
          search: this.params.search,
          page: this.params.page ? String(this.params.page) : "1",
          registration_status: this.params.registration_status
            ? String(this.params.registration_status)
            : "0"
        }
      })
      .catch(() => {});
  }

  // 行をクリックした際の挙動
  public rowClick(supplier: ISupplier) {
    this.$router.push(`/admin/supplier/${supplier.id}`);
  }
}
